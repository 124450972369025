<template>
	<div class="row">
      <div class="col-8">
         <div class="card">
            <div class="card-body">
               <div class="d-flex h-100 ml-3">
                  <div class="d-flex align-self-center w-100">
                     <img class="rounded d-flex alifn-self-center my-2 filter" :src="dadosUsuario.avatar != null ? dadosUsuario.avatar : require('@/assets/admin/images/discord.jpeg')" alt="Foto de perfil" width="250">
                     <div class="w-100">
                        <div class="ms-4 my-2">
                           <h3 class="mb-1 limitador filter color-theme">{{dadosUsuario.nomeCompleto}}</h3>
                           <h5 class="text-secondary limitador filter">{{dadosUsuario.userName}}</h5>
                        </div>
                        <div class="d-flex col-12 mt-5 filter">
                           <div class="col-4 d-flex justify-content-center">
                              <div class="body">
                                 <div class="h6">Cursos</div>
                                 <div class="mt-1 h1 color-theme text-center">{{dadosUsuario.totalModulos.toString().padStart(2, '0')}}</div>
                              </div>
                           </div>
                           <div class="col-4 d-flex justify-content-center">
                              <div class="body">
                                 <div class="h6">Scripts</div>
                                 <div class="mt-1 h1 color-theme text-center">{{dadosUsuario.totalProdutos.toString().padStart(2, '0')}}</div>
                              </div>
                           </div>
                           <div class="col-4 d-flex justify-content-center">
                              <div class="body">
                                 <div class="h6">Pacotes</div>
                                 <div class="mt-1 h1 color-theme text-center">{{dadosUsuario.pacote.length.toString().padStart(2, '0')}}</div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-4">
         <div class="card">
            <div class="card-body">
               <div class="position-absolute btnDespesas" @click="editDados()">
                  <i class="fa fa-pencil font-14 cursor-pointer"></i>
               </div>
               <div class="mt-2 mb-2 ms-3">
                  <div>
                     <small class="text-muted">Nome completo: </small>
                     <p class="mb-2 limitador">{{dadosUsuario.nomeCompleto != null && dadosUsuario.nomeCompleto != '' ? dadosUsuario.nomeCompleto : '*Não registrado'}}</p>
                  </div>
                  <div>
                     <small class="text-muted">User do discord: </small>
                     <p class="mb-2 limitador">{{dadosUsuario.userName != null && dadosUsuario.userName != '' ? dadosUsuario.userName : '*Não registrado'}}</p>
                  </div>
                  <div>
                     <small class="text-muted">CPF/CNPJ: </small>
                     <span class="mb-2 limitador"> {{dadosUsuario.documento != null && dadosUsuario.documento != '' ? dadosUsuario.documento : '*Não registrado'}}</span>
                  </div>
                  <div>
                     <small class="text-muted">Email: </small>
                     <p class="mb-2 limitador">{{dadosUsuario.email != null && dadosUsuario.email != '' ? dadosUsuario.email : '*Não registrado'}}</p>
                  </div>
                  <div>
                     <small class="text-muted">Whatsapp: </small>
                     <p class="mb-2 limitador">{{dadosUsuario.whatsApp != null && dadosUsuario.whatsApp != '' ? dadosUsuario.whatsApp : '*Não registrado'}}</p>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <!-- modalDados -->
      <div class="modal fade" id="modalDados" tabindex="-1" data-bs-backdrop="static" aria-labelledby="modalDadosLabel" aria-hidden="true">
         <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title h4" id="modalDadosLabel">Dados</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true"></span></button>
               </div>
               <div class="modal-body">
                  <div class="card p-3 mb-0">
                     <div class="row">
                        <div class="col-12 col-lg-8 mb-3">
                           <label><i class="far fa-user font-13 color-theme mr-1"></i> Nome completo</label>
                           <input type="text" class="form-control rounded" v-model="dados.nomeCompleto"/>
                        </div>
                        <div class="col-12 col-lg-4 mb-3">
                           <label><i class="far fa-file font-13 color-theme mr-1"></i> CPF / CNPJ</label>
                           <input type="text" class="form-control rounded" v-mask="['###.###.###-##', '##.###.###/####-##']" v-model="dados.documento"/>
                        </div>
                        <div class="col-12 col-lg-6 mb-3">
                           <label><i class="fab fa-whatsapp font-13 color-theme mr-1"></i> Whatsapp</label>
                           <input type="text" class="form-control rounded" v-mask="'(##) # ####-####'" v-model="dados.whatsApp"/>
                        </div>
                        <div class="col-12 col-lg-6 mb-3">
                           <label><i class="far fa-envelope font-13 color-theme mr-1"></i> Email</label>
                           <input type="text" class="form-control rounded" maxlength="50" v-model="dados.email"/>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-round btn-primary" @click="saveDadosUsuario">Salvar</button>
                  <button type="button" class="btn btn-round btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'

export default {
	name: 'MeusCursos',
	data: function() {
		return {
         pesquisa: {'nome': '', 'resultado': []},
         dados: {'nomeCompleto': null, 'documento': null, 'email': null, 'whatsApp': null},
         screenWidth: window.innerWidth,
		}
	},
	components: {
      
   },
	computed: {
		...mapState({
			dadosUsuario:  state => state.dadosUsuario,
         urlRest: state => state.urlRest
		})
	},
	methods: {
      saveDadosUsuario : function () {
         let ref = this;

			this.$store.dispatch('alternarTelaCarregamento', true)
         this.$axios({
            method: 'post',
            url: this.urlRest +'servicos/saveDadosUsuario',
            headers: {
					'Content-Type': 'application/json'
            },
				data: this.dados

         }).then(function () {
            $('#modalDados').modal('hide')
            ref.$store.dispatch('atualizarDadosUsuario', ref.dados)
            ref.$toast.fire({
               icon: 'success',
               title: 'Salvo!',
               timer: 1500
            })
         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogar')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else if (error.response.status == 400) {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Data inválida!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            $('#modalEditarCurso').modal('hide')
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      },
      editDados : function () {
         this.dados = JSON.parse(JSON.stringify(this.dadosUsuario));
         $('#modalDados').modal('show')
      }
	},
   mounted() {
      this.dados = JSON.parse(JSON.stringify(this.dadosUsuario))
   }
}

</script>

<style scoped>
.cardCurso {
	width: 100%;
	height: 100%;
}

.cardDiscord {
   background: rgb(47,49,54);
   background: radial-gradient(circle, rgba(47,49,54,1) 0%, rgba(31,25,25,1) 100%); 
}

.square {
  width: 50vw;
  height: auto;
  /* background-color: blue; */
}

.cards-6 {
	display: grid;
   grid-template-columns: 2fr 1fr;
   gap: 0.5rem;
   padding: 0;
   list-style-type: none;
}

.btnDespesas {
	font-size: 13px;
	color: var(--dark-color);
	display: flex;
	justify-content: center;
	align-items: center;
	top: 5px;
	right: 5px;
	background-color: transparent;
	width: 40px;
	height: 28px;
	transition: 0.3s;
}

.filter {
   /* filter: drop-shadow(-2px 2px 1px rgb(0, 0, 0)); */
}

@media (max-width: 768px) {
	.elseCard {
		height: 250px !important;
	}

	.cards-5 {
		display: grid;
		grid-template-columns: 1fr;
		gap: 0.5rem;
		padding: 0;
		list-style-type: none;
	}

	.cards-3 {
		display: grid;
		grid-template-columns: 1fr;
		gap: 0.5rem;
		padding: 0;
		list-style-type: none;
	}

	.cards-4 {
		display: grid;
		grid-template-columns: 2.02fr 1fr;
		gap: 0.5rem;
		padding: 0;
		list-style-type: none;
	}

	.card {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		margin: 0;
	}

	.bodyCard {
		margin: 15px;
	}

	.footerCard {
		margin-top: 30px;
		margin-bottom: 15px;
	}

	.cards-6 {
		display: grid;
		grid-template-columns: 1fr;
		gap: 0.5rem;
		padding: 0;
		list-style-type: none;
	}

	.btn24M, .btn12M, .btn6M{
		display: none;
	}

	.titleCard {
		font-size: 18px;
	}

	.dropdown-content {
		position: absolute;
		max-width: 250px;
		right: 3em;
		top: 1.5em;
		z-index: 2;
	}
}
</style>